/* 中文 */
export default {
  emailRegister: '邮箱注册',
  registerNotice: '未注册的账户将自动注册并登录',
  enterEmail: '请输入您的邮箱地址',
  enterCode: '请输入您的验证码',
  getCode: '获取验证码',
  signIn: '立即登录',
  signPropt: '登录即表示您同意',
  policy: '隐私政策',
  agreement: '用户协议',
  emailRuleError: '邮箱格式不正确',

  starcard: `星卡会员权益`,
  more: `更多`,
  describe: `星卡是聚宝盆推广系统的权益证明。只有拥有星卡才能享受聚宝盆推广系统的一系列福利和权益。星卡分为体验星卡和专属星卡，星卡用户可以获得现金奖励。`,
  starReward: `星卡推广奖励`,
  unlock: `解锁`,
  text1: `· 邀请奖励：直接或间接邀请用户购买星卡会员可获得10%和5%的奖励。`,
  text2: `· 投资版权推广奖励：直接或间接邀请用户投资版权推广，获得日收益的10%和5%的奖励。`,
  text3: `· 观影奖励：直接邀请用户解锁单集可享受40%的奖励，或订阅短剧会员可获得20%的奖励。`,
  starClu: `星群奖励`,
  text4: `具体奖励规则请咨询官方客服或邀请人`,
  copyright: `版权众筹`,
  td1: `年化收益率`,
  td2: `初始投资金额`,
  td3: `已完成/总金额`,
  warning: `*根据推广的实际效果，收益会每日波动。`,
  schedule: `众筹进度`,
  buynow: `立即购买`,
  download: `下载`,
  go: `前往`,
  android: `安卓`,
  apple: `应用商店`,
  Characteristic: `特点`,
  Participation: `参与金额`,
  method: `支付方式`,
  amount: `支付金额`,
  jump: `跳转支付`,
  name1: `短剧`,
  name2: `多种类型`,
  name3: `星卡会员`,
  name4: `管道收入`,
  describe1: `质量保证`,
  describe2: `想你所想`,
  describe3: `百亿补贴`,
  describe4: `副业之首`,
  warning1: `输入内容不能为空`,
  success1: `绑定成功`,
  ends: `倒计时结束`,
  warning2: `您还未登录，请先登录`,
};
